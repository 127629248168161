body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html,body{
  overflow-x: hidden;
}

.content-1{

  background: linear-gradient(to right, #080808b9 0%, #5bcefce3 100%);
 font-size:70px;
 color:white;
 height:inherit;
 
 padding-top:20px;
}

.bg-orange{
  background-color: #ff8246;
}

.text-orange{
 color: #ff8246;
}

.navbar-2 i{

 color: rgb(226, 226, 226);
 background-color:rgb(45, 138, 243);
 border:2px solid rgb(236, 236, 236);
}


.bg-blue{
  background-color: #373a6d;

}
.subscribe{


  background-color: #005aa3d0;
}


.content-2{

  background: linear-gradient(to right, #0d0e0e3d 0%, #d0ffc7c2 100%);
 font-size:77px;
 color:white;
 height:inherit;
 height: 30vh;
 
 padding-top:20px;
}
.carousel-item-1{
  background-image: url("../src/images/magzineB.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  
  background-position-y: -240px;
  
  
  
  
}
.bg-oprimary{

  background-color: rgba(0,77,97,0.6) ;
  
}


.showcase{
  background-image: url("../src/images/magzineB2.jpg");
  
}

.carousel-item-2{
  background-image: url("../src/images/magzineB2.jpg");
  background-position-y: -360px;
  
  
}

.navbar-2 i {
  font-size: 25px;
  color: azure;
  



}

.stories{
  background-image: url('../src/images/backgroundStory.jpeg');
}

.stories .content{
  background-color: rgba(7, 7, 7, 0.863);
  height: inherit;
  padding:50px;
  font-size: 37px;
 
  
}



.article{


  background-image: url('https://images.pexels.com/photos/1145720/pexels-photo-1145720.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500');
  height: 60vh;
}

.article .content{

  background-color: rgba(0, 0, 0, 0.637);
  height: inherit;
  line-height: 1.8em;
  


}

.article .content  .card{
  
  top: 50%;
  position: relative;
  background-image: url('https://images.pexels.com/photos/19670/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=750&w=1260');

  background-size: cover;

}

.article .content h1{

  line-height: 1.7em;
}

.introduce img{
  height: 220px;
  width: 100%;
  
  margin-bottom: 20px;
}
.introduce  .card h3{
  text-align: center;
}

.editions img{
  width: 100%;
  height: 310px;

}
.editions .magzineImages {
  position: relative;

}
.magzineImages:hover > 
.content{
  visibility: visible !important;
}
.magzineImages .content i{
  cursor: pointer;
}
.editions .magzineImages .content{
  top:0;
  right: 0px;

  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.788);
  visibility: hidden;
}

.editions .magzineImages .content .panel{
  margin-top: 10vh;
}
@media screen and (max-width: 767px) {
  .stories .content{
    font-size: 37px;
  }
.introduce img{
  height: 320px;
 width:100%;
  margin-bottom: 20px;
}
  .stories .forwardButton i{
    font-size: 53px !important;
  }

  .stories .content{
    padding-left:0px !important;
  }
}


.editions {
  background-image: url('./images/snowflakes.jpg');
  
}

.editions .show{
  background-color: rgba(235, 237, 240, 0.308);
}
@media screen and (max-width: 625px) {
  .navbar-brand {
    margin:auto !important;
  }
  nav ul{
    margin:auto;

  } 

 .carousel-item-1{
    background-position-y: -40px;
  }
  .carousel-item-2{
    background-position-y: -220px;
    background-position-x:-100px;
  }
 
}
.base-footer .info{
  display:inline-block;

}
@media screen and (max-width:576px){
  .stories .content{
    font-size: 27px !important;
  }
  .prices h1{
    font-size: 25px !important;
  }

 .introduce {
    width: 100% !important;
    
    
  }
.introduce img{
  width: 80%;
}

.introduce {

}

.introduce .card{
    padding: 8px !important;
  }

  nav a {
    font-size: 22px !important;
  }
  nav li img{
    width:30px;

  }
  .nav-sec li a, .backButton{
    padding: 0px !important;
    background-color: transparent !important
    ;
  }

  .base-footer .visitors h2, .base-footer .visitors h4{
    font-size: 22px !important;
  }
}
@media screen and (max-width: 992px) {

  .introduce{
    width: 90%;
    margin: 0px auto;
  }
  .stories .content{
    text-align: center;
  }

  .stories .bookImg{
    display: none;
  }

}

@media screen and (max-width: 310px) {

  .introduce img{
    width:100% !important;
  }
}
  @media screen and (max-width: 410px) {

.showcase .subscribeContainer{
   padding: 5px 0px !important;
   
   
  }

  
}

